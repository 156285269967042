import React, { useEffect } from "react";
import SalePrice from "../images/sale-price.svg";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../../components/HeaderComponent";
import "../style/sucess.css";
import ArrowDown from "../images/arrow-down.svg";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useSelector } from "react-redux";

export const SuccessCOmponent = () => {
  const navigate = useNavigate();
  const { price, deal_id, car } = useSelector((state) => state.quoteReducer);

  const navigateToCotizador = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!car) {
      navigateToCotizador();
    }
  }, []);

  let isMobile = useMediaQuery("(max-width: 500px)");

  return (
    <>
      <div
        style={{ height: "100vh", overflow: "hidden" }}
        className="container-app-all"
      >
        <HeaderComponent />
        <div className="background-bottom-black-purple"/>
        <div
          className="container-success d-flex align-items-center justify-content-start flex-column"
          style={{ padding: "10px" }}
        >
          <div className="d-flex align-items-center justify-content-between flex-column mb-5">
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <p className="title-success mt-2 mt-md-5">
                  Tu cotización fue {isMobile ? <br /> : null} realizada con
                  éxito.
                </p>
                <img
                  src={ArrowDown}
                  alt="arrow-down"
                  style={{
                    marginTop: "-10px",
                  }}
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center container-card-cotizacion mt-3">
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "100%" }}
                >
                  <img
                    src={SalePrice}
                    alt="auta-button"
                    className="card-icon-cotizacion"
                  />
                  <div className="d-flex align-items-start justify-content-center flex-column ms-2 ms-md-3">
                    <p className="car-model-cotizacion">
                      {car?.brand} {car?.model}
                    </p>
                    <p className="car-version-cotizacion">{car?.version}</p>
                    <p className="car-year-kms-cotizacion mt-2">
                      {car?.year} | {car?.kms} kms
                    </p>
                  </div>
                </div>
                <div
                  className="container-cotizacion-price d-flex flex-column align-items-center justify-content-center mt-3"
                  style={{ width: isMobile ? "100%" : "" }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "100%" }}
                  >
                    <p className="text-offer-initial me-md-5">Oferta inicial</p>
                    <div className="d-flex align-items-center justify-content-center ms-md-5 d-none d-md-flex">
                      <p className="price-cotizacion">{price}</p>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="d-flex align-items-start justify-content-start flex-column"
                  >
                    <p className="footer-card-price mt-md-1">
                      Rápido, Fácil y Seguro.
                    </p>
                    <p className="text-description-cotizacion mt-1  d-none d-md-flex">
                      *Oferta sujeta a inspección
                    </p>
                  </div>
                  <div
                    className="d-flex align-items-start justify-content-start flex-column d-md-none"
                    style={{ width: "100%" }}
                  >
                    <p className="price-cotizacion">{price}</p>
                    <p className="text-description-cotizacion mt-1">
                      *Oferta sujeta a inspección
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center mt-3">
                  <p className="text-description-cotizacion mt-1 mb-2">
                    En instantes recibirás la oferta por WhatsApp
                    {isMobile ? <br /> : null} para continuar con la venta de tu
                    auto.
                  </p>
                </div>
                <div className="mt-2 mt-md-5 container-btn-success">
                  <button className="purple-button-inspect me-2">
                    <a
                      href={`https://turnos.auta.com.ar/inspeccion/${deal_id}`}
                      target="_blank"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Vender mi auto
                    </a>
                  </button>
                  <button
                    className="white-button-inspect me-md-2"
                    onClick={navigateToCotizador}
                  >
                    Volver a Auta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
