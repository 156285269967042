import React, { useEffect, useState } from "react";
import "../../style/ButtonGroupStepper.css";
import BaseModalComponet from "../../../../components/BaseModalComponet";
import ManualQuoteModalComponent from "../../../../components/ManualQuoteModalComponent";
import ModalMinKmsComponent from "../../../../components/ModalMinKmsComponent";
import { colors } from "../../../../styles/colors";
import { getBrands, getModels, getVersion } from "../../service/fetchDataCar";
import { TitleHomeWithIcon } from "../../../../components/TitleHomeWithIcon";
import { ButtonGroupComponent } from "./ButtonGroupComponent";
import { CarouselComponent } from "./CarouselComponent";
import { FooterCotizadorComponent } from "../FooterCotizadorComponent";

export default function ButtonGroupStepperSaleComponent({
  dataYears,
  isTitleShow,
}) {
  const [currentView, setCurrentView] = useState("step1");
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const isMobile = true;
  const [showDropdownKms, setShowDropdownKms] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
  const [modalSize, setModalSize] = useState("xs");
  const [isGnc, setIsGnc] = useState("");
  const [isContinuing, setIsContinuing] = useState(false);

  const handleInputChange = (e) => {
    setIsGnc("");
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (inputValue === "") {
      setNumber(""); // Si inputValue está vacío, establece el estado como una cadena vacía
    } else {
      const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNumber(formattedNumber);
    }
  };

  const toggleRenewModal = () => {
    setIsRenewModalOpen(!isRenewModalOpen);
    setModalSize("xs");
  };

  const [selectedOptions, setSelectedOptions] = useState({
    year: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
  });
  const [dataBrands, setDataBrands] = useState();
  const [dataModels, setDataModels] = useState();
  const [dataVersions, setDataVersions] = useState();
  const [dataVersionsSearch, setDataVersionsSearch] = useState();
  const [dataModelsSearch, setDataModelsSearch] = useState();
  const [dataBrandsSearch, setDataBrandsSearch] = useState();
  const [showModalForm, setShowModalForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getBrands(selectedOptions?.year?.name, setDataBrands, setDataBrandsSearch);
  }, [selectedOptions?.year]);

  useEffect(() => {
    getModels(
      selectedOptions?.brand?.name,
      selectedOptions?.year?.name,
      setDataModels,
      setDataModelsSearch
    );
  }, [selectedOptions?.brand]);

  useEffect(() => {
    getVersion(
      selectedOptions?.year?.name,
      selectedOptions?.brand?.name,
      selectedOptions?.model?.name,
      setDataVersions,
      setDataVersionsSearch
    );
  }, [selectedOptions?.model]);

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber, isAuth) => {
    // setSelectedButton(buttonNumber);
    const formattedNumber = parseInt(number.replace(/\./g, ""), 10);
    if (buttonNumber === 6) {
      if (!isNaN(formattedNumber) && formattedNumber <= 1000) {
        if (!isContinuing) {
          setShowModal(true);
        } else {
          setSelectedButton(buttonNumber);
        }
      } else {
        setSelectedButton(buttonNumber);
      }
    } else {
      setSelectedButton(buttonNumber);
    }
  };
  const handleOptionSelect = (option, value, id) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: { id: id, name: value },
    }));
  };

  const handleItemClick = (selectedValue) => {
    setSelectedItem(selectedValue);
  };

  const goToHome = () => {
    window.location.reload();
  };

  return (
    <>
      <>
        {currentView !== "step3" ? (
          <div className="mt-4">
            <TitleHomeWithIcon
              title={"¡Cargá tu auto!"}
              fontSize={"22px"}
              colorText={colors.purple.darkPurple}
              colorIcon={colors.purple.purple}
            />
          </div>
        ) : null}
        {isTitleShow && currentView !== "step3" ? (
          <ButtonGroupComponent
            selectedButton={selectedButton}
            selectedOptions={selectedOptions}
            handleButtonClick={handleButtonClick}
            number={number}
            isGnc={isGnc}
          />
        ) : null}
        <div className="mobile-component-display">
          <CarouselComponent
            dataYears={dataYears}
            selectedOptions={selectedOptions}
            selectedButton={selectedButton}
            isMobile={isMobile}
            handleOptionSelect={handleOptionSelect}
            handleItemClick={handleItemClick}
            setSelectedOptions={setSelectedOptions}
            handleButtonClick={handleButtonClick}
            selectedItem={selectedItem}
            dataBrandsSearch={dataBrandsSearch}
            dataBrands={dataBrands}
            dataModelsSearch={dataModelsSearch}
            dataModels={dataModels}
            dataVersionsSearch={dataVersionsSearch}
            dataVersions={dataVersions}
            setDataBrands={setDataBrands}
            setDataModels={setDataModels}
            setDataVersions={setDataVersions}
            handleInputChange={handleInputChange}
            isGnc={isGnc}
            setIsGnc={setIsGnc}
            number={number}
            setNumber={setNumber}
            currentView={currentView}
            handleStartClick={goToHome}
            setShowDropdownKms={setShowDropdownKms}
            setCurrentView={setCurrentView}
            setShowModalForm={setShowModalForm}
            etShowModal={setShowModal}
          />
        </div>
      </>
      <FooterCotizadorComponent />
      <BaseModalComponet
        isOpen={isRenewModalOpen}
        onClose={toggleRenewModal}
        size={modalSize}
      >
        <ManualQuoteModalComponent />
      </BaseModalComponet>
      {showModal && (
        <ModalMinKmsComponent
          show={showModal}
          kms={number}
          setShow={setShowModal}
          setIsContinuing={setIsContinuing}
          setSelectedButton={setSelectedButton}
          isMobile={isMobile}
          setIsModalOpen={setIsModalOpen}
          setShowModalForm={setShowModalForm}
        />
      )}
    </>
  );
}
