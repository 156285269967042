import React from "react";
import { Modal } from "react-bootstrap";
import "../../style/modalInterior.css";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import ArrowDown from "../../images/arrow-down-space.svg";

const ModalInterior = ({
  show,
  setShow,
  title,
  subtitle,
  subtitleTwo,
  textBtnLeft,
  textBtnRight,
  handleEventLeft,
  handleEventRight
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={isPageWide ? "modal-interior" : "modal-interior-mobile"}
      centered
      style={{ whiteSpace: "pre-wrap" }}
    >
      <div className="background-custom-modal-interior" style={{ bottom: isPageWide ? "-27px" : "-26px", height: isPageWide ? "41%" : "52%" }} />
      <Modal.Body style={{ width: "100%" }}>
        <div
          /* style={{ height: "90%" }} */
          className="d-flex align-items-center justify-content-between flex-column"
        >
          <div style={{ fontSize: isPageWide ? "20px" : "16px" }}>
            <p className="title-modal-interior" style={{ fontSize: isPageWide ? "24px" : "20px" }}>
              {title}
            </p>
            <img src={ArrowDown} alt="arrow-down" className="arrow-down" style={{ marginTop: "-15px" }}/>
            {isPageWide ?
              <p className="subtitle-modal-interior mb-3">
                {subtitle}
              </p>
              :
              <p className="subtitle-modal-interior mb-3">
                {subtitle}
              </p>
            }
            <p className="subtitle-2-modal-interior mb-4">
              {subtitleTwo}
            </p>
          </div>
          <div
            className={`d-flex align-items-center justify-content-around flex-${isPageWide ? "row" : "column"} mt-5`}
          /* style={{ height: "52vh" }} */
          >
            {
              isPageWide ?
                <>
                  <button className="btn-purple-kms-interior" onClick={handleEventLeft}>
                    {textBtnLeft}
                  </button>
                  <button className={`btn-white-kms-interior ms-5`} onClick={handleEventRight}>
                    {textBtnRight}
                  </button>
                </>
                :
                <>
                  <button className={`btn-white-kms-interior`} onClick={handleEventRight}>
                    {textBtnRight}
                  </button>
                  <button className="btn-purple-kms-interior mt-3" onClick={handleEventLeft}>
                    {textBtnLeft}
                  </button>
                </>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInterior;
